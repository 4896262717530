export const routes = [
  {
    label: 'Services',
    href: '/services',
  },
  {
    label: 'AI Consultation',
    href: '',
    subMenus: [
      {
        label: 'Demo',
        href: '/demo',
      },
      {
        label: 'Custom AI',
        href: '/custom-ai',
      },
    ],
  },
  {
    label: 'Products',
    href: '/products',
  },
  {
    label: 'Careers',
    href: '/careers',
  },
  {
    label: 'Blog',
    href: '/blogs',
  },
  {
    label: 'Contact',
    href: '/contact',
  },
]
