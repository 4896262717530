import * as React from 'react'

const LogoDataWow = (props) => (
  <svg
    width='205'
    height='32'
    fill='currentColor'
    viewBox='0 0 205 32'
    {...props}
  >
    <g fill='none' fillRule='evenodd'>
      <path
        fill='#4A4A4A'
        d='M26.331 31.237h3.95V18.51h-3.95zM26.331 15.438h3.95V.956h-3.95zM17.554 31.237h3.95v-19.31h-3.95z'
      />
      <path
        fill='#46D7CE'
        d='M17.554 8.856h3.95v-7.9h-3.95zM8.777 31.237h3.95v-7.46h-3.95z'
      />
      <path
        fill='#4A4A4A'
        d='M8.777 20.705h3.95V.956h-3.95zM0 31.237h3.95V.957H0z'
      />
      <g fill='#4A4A4A'>
        <path d='M59.42 15.933V.383h3.002v30.392h-3.001v-2.418c-1.001 1.293-2.877 2.919-5.92 2.919-4.378 0-8.422-3.086-8.422-9.173 0-5.961 4.002-9.047 8.421-9.047 3.336 0 5.128 1.835 5.92 2.877zm-5.586-.208c-2.96 0-5.67 2.168-5.67 6.378 0 3.711 2.084 6.504 5.67 6.504 3.669 0 5.753-3.043 5.753-6.462 0-4.46-3.085-6.42-5.753-6.42zm28.701.208v-2.46h3.002v17.302h-3.002v-2.418c-1 1.293-2.877 2.919-5.92 2.919-4.378 0-8.422-3.086-8.422-9.173 0-5.961 4.003-9.047 8.422-9.047 3.335 0 5.128 1.835 5.92 2.877zm-5.586-.208c-2.96 0-5.67 2.168-5.67 6.378 0 3.711 2.084 6.504 5.67 6.504 3.668 0 5.753-3.043 5.753-6.462 0-4.46-3.085-6.42-5.753-6.42zm21.53.5h-3.002v14.55h-3.002v-14.55h-1.834v-2.752h1.834V7.22h3.002v6.253h3.002v2.752zm17.278-.292v-2.46h3.001v17.302h-3.001v-2.418c-1 1.293-2.877 2.919-5.92 2.919-4.378 0-8.422-3.086-8.422-9.173 0-5.961 4.002-9.047 8.422-9.047 3.335 0 5.128 1.835 5.92 2.877zm-5.587-.208c-2.96 0-5.67 2.168-5.67 6.378 0 3.711 2.085 6.504 5.67 6.504 3.669 0 5.753-3.043 5.753-6.462 0-4.46-3.085-6.42-5.753-6.42zm12.942-2.252h3.335l5.128 11.757 5.42-13.09 5.378 13.09 5.17-11.757h3.335l-8.588 18.511-5.337-12.924-5.336 12.924-8.505-18.51zm40.208 17.803c-5.253 0-9.172-3.836-9.172-9.09 0-5.252 3.877-9.13 9.172-9.13 5.295 0 9.172 3.878 9.172 9.13 0 5.254-3.919 9.09-9.172 9.09zm0-15.551c-3.169 0-6.087 2.251-6.087 6.462 0 4.252 3.002 6.42 6.087 6.42 3.127 0 6.087-2.21 6.087-6.42 0-4.253-2.918-6.462-6.087-6.462zm12.442-2.252h3.335l5.128 11.757 5.42-13.09 5.378 13.09 5.17-11.757h3.335l-8.588 18.511-5.337-12.924-5.336 12.924-8.505-18.51z' />
      </g>
    </g>
  </svg>
)

const LogoDataWowWhite = (props) => {
  return (
    <svg
      width='204'
      height='32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M26.331 30.855h3.95V18.128h-3.95v12.727zM26.331 15.056h3.95V.574h-3.95v14.482zM17.554 30.855h3.95v-19.31h-3.95v19.31zM17.554 8.473h3.95v-7.9h-3.95v7.9zM8.777 30.855h3.95v-7.46h-3.95v7.46zM8.777 20.366h3.95V.618h-3.95v19.748zM0 30.855h3.95V.574H0v30.281zM59.42 15.55V0h3.002v30.393h-3.001v-2.418c-1.001 1.292-2.877 2.918-5.92 2.918-4.378 0-8.422-3.085-8.422-9.172 0-5.962 4.002-9.047 8.421-9.047 3.336 0 5.128 1.835 5.92 2.877zm-5.586-.208c-2.96 0-5.67 2.168-5.67 6.38 0 3.71 2.084 6.503 5.67 6.503 3.669 0 5.753-3.043 5.753-6.462 0-4.461-3.085-6.42-5.753-6.42zm28.701.209v-2.46h3.002v17.302h-3.002v-2.418c-1 1.292-2.876 2.918-5.92 2.918-4.378 0-8.422-3.085-8.422-9.172 0-5.962 4.003-9.047 8.422-9.047 3.335 0 5.128 1.835 5.92 2.877zm-5.587-.209c-2.96 0-5.67 2.168-5.67 6.38 0 3.71 2.085 6.503 5.67 6.503 3.67 0 5.754-3.043 5.754-6.462 0-4.461-3.085-6.42-5.754-6.42zm21.53.5h-3v14.551h-3.003v-14.55h-1.834V13.09h1.834V6.837h3.002v6.254h3.002v2.752zm17.279-.291v-2.46h3.001v17.302h-3.001v-2.418c-1.001 1.292-2.877 2.918-5.921 2.918-4.377 0-8.421-3.085-8.421-9.172 0-5.962 4.002-9.047 8.421-9.047 3.336 0 5.129 1.835 5.921 2.877zm-5.587-.209c-2.96 0-5.67 2.168-5.67 6.38 0 3.71 2.085 6.503 5.67 6.503 3.669 0 5.753-3.043 5.753-6.462 0-4.461-3.085-6.42-5.753-6.42zm12.942-2.25h3.335l5.128 11.756 5.42-13.091 5.378 13.091 5.17-11.757h3.335l-8.588 18.511-5.337-12.924-5.336 12.924-8.505-18.51zm40.208 17.801c-5.253 0-9.172-3.835-9.172-9.088 0-5.253 3.877-9.13 9.172-9.13 5.295 0 9.172 3.877 9.172 9.13 0 5.253-3.919 9.088-9.172 9.088zm0-15.55c-3.169 0-6.087 2.25-6.087 6.462 0 4.252 3.002 6.42 6.087 6.42 3.127 0 6.087-2.21 6.087-6.42 0-4.253-2.918-6.463-6.087-6.463zm12.442-2.252h3.335l5.128 11.757 5.42-13.091 5.378 13.091 5.17-11.757h3.335l-8.588 18.511-5.337-12.924-5.336 12.924-8.505-18.51z'
        fill='#fff'
      />
    </svg>
  )
}

const LogoDataWowSM = (props) => (
  <svg
    width='34'
    height='34'
    fill='currentColor'
    viewBox='0 0 34 34'
    {...props}
  >
    <g fill='none' fillRule='evenodd'>
      <path
        fill='#4A4A4A'
        d='M28.8 33.366h4.32v-13.92H28.8zM28.8 16.086h4.32V.246H28.8zM19.2 33.366h4.32v-21.12H19.2z'
      />
      <path
        fill='#46D7CE'
        d='M19.2 8.886h4.32V.246H19.2zM9.6 33.366h4.32v-8.16H9.6z'
      />
      <path
        fill='#4A4A4A'
        d='M9.6 21.846h4.32V.246H9.6zM0 33.366h4.32V.246H0z'
      />
    </g>
  </svg>
)

const LogoDataWowWhiteSM = (props) => {
  return (
    <svg
      width='31'
      height='31'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M26.331 30.855h3.95V18.128h-3.95v12.727zM26.331 15.056h3.95V.574h-3.95v14.482zM17.554 30.855h3.95v-19.31h-3.95v19.31zM17.554 8.473h3.95v-7.9h-3.95v7.9zM8.777 30.855h3.95v-7.46h-3.95v7.46zM8.777 20.366h3.95V.618h-3.95v19.748zM0 30.855h3.95V.574H0v30.281z'
        fill='#fff'
      />
    </svg>
  )
}

export {LogoDataWow, LogoDataWowSM, LogoDataWowWhite, LogoDataWowWhiteSM}
