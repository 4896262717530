import React from 'react'
import Router, {useRouter} from 'next/router'
import Link from 'next/link'
import classNames from 'classnames'

import {logEvent} from '@lib/gtm'
import {useScrollInfo} from '@lib/hooks'
import {routes} from '@lib/routes'

import {IconCaretDown} from '@components/icon'
import {LogoDataWow} from '@components/Logo'

const SiteHeader = (props) => {
  const {logo} = props

  const router = useRouter()
  const [showMenu, setShowMenu] = React.useState(false)
  const {currentScrollPos, direction} = useScrollInfo()
  const isCurrentPage = (href) => router.pathname === href

  React.useEffect(() => {
    setShowMenu(false)
  }, [currentScrollPos])

  React.useEffect(() => {
    let mounted = true
    Router.events.on('routeChangeStart', () => {
      mounted && setShowMenu(false)
    })

    return () => {
      mounted = false
    }
  }, [])

  return (
    <header
      className={classNames('Nav', {
        'Nav--hide': currentScrollPos > 0 && direction === 'down',
        'Nav--scroll': currentScrollPos > 0,
      })}
    >
      <div className='container'>
        <Link href='/'>
          <a
            className='Nav__logo-brand'
            title='datawow.co.th'
            onClick={() => {
              logEvent({
                ga: {
                  category: 'Homepage',
                  action: 'Click Homepage',
                },
              })
            }}
          >
            {logo ? (
              logo
            ) : (
              <>
                <LogoDataWow />
              </>
            )}
          </a>
        </Link>
        <nav
          className={classNames('Nav__links', {
            'Nav__links--show': showMenu,
          })}
          onClick={() => setShowMenu(false)}
        >
          {routes.map(({label, href, subMenus}, index) => {
            return (
              <div
                key={`menu${index}`}
                className={classNames('Nav__item', {
                  'Nav__item--active': isCurrentPage(href),
                })}
              >
                <Link href={href || '#'}>
                  <a
                    title={label}
                    className={classNames('Nav__a', {
                      'Nav__a--disabled': isCurrentPage(href) || !href,
                    })}
                    onClick={() => {
                      logEvent({
                        ga: {
                          category: label,
                          action: `Click ${label}`,
                        },
                      })
                    }}
                  >
                    {label}
                  </a>
                </Link>
                {subMenus && (
                  <div className='Nav__sub-menus'>
                    {subMenus.map((subMenu) => {
                      const {href, label} = subMenu
                      return (
                        <Link key={href} href={href} passHref>
                          <a
                            title={label}
                            className='Nav__sub-menus__a'
                            onClick={() => {
                              logEvent({
                                ga: {
                                  category: label,
                                  action: `Click ${label}`,
                                },
                              })
                            }}
                          >
                            {label}
                          </a>
                        </Link>
                      )
                    })}
                  </div>
                )}
              </div>
            )
          })}
          <div className='SelectLang'>
            <div className='SelectLang__value'>
              EN <IconCaretDown />
            </div>

            <div className='SelectLang__popup'>
              <a
                href='https://www.datawow.co.th/'
                target='_blank'
                rel='nofollow noopener noreferrer'
                title='TH'
              >
                TH
              </a>
            </div>
          </div>
        </nav>

        <div className='mobile'>
          <div className='mobile__lang'>
            <div className='mobile__lang__value'>EN </div>
            <hr />
            <a
              href='https://www.datawow.co.th/'
              target='_blank'
              rel='nofollow noopener noreferrer'
              title='TH'
              className='mobile__lang__value'
            >
              TH
            </a>
          </div>
          <button
            className='Nav__toggle'
            onClick={() => setShowMenu((s) => !s)}
          >
            <img src='/images/menu.svg' />
          </button>
        </div>
      </div>
    </header>
  )
}

export default SiteHeader
